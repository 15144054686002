.home-page #summer-night-title {
  text-align: center;
  padding: 1rem;
}

.score-board-team-cards {
  margin-top: 0.9rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1;
}

.team-score-card-wrapper {
  color: black;
  text-align: center;
  grid-column: auto; 
  grid-row: 1;
  margin: 0.2rem;
}

.team-score-card {
  font-size: 0.8em;
  border-radius: 0.3rem;
}

.team-score-card h5 {
  font-size: 1.1rem;

  padding: 0.3rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.team-score-card p {
  padding: 0.3rem;
}

#team-1-score-card {
  background-color: #EE4E00;
}
#team-2-score-card {
  background-color: #FFF7B0;
}
#team-3-score-card {
  background-color: #6EC0FF;
}
#team-4-score-card {
  background-color: #AF4684;
}

.home-page-game-end-logo {
  width: 50%;
}